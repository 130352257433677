<template>
  <select
    class="v3-year-picker"
    v-model="selectedYear"
    @change="selectYear(selectedYear)"
  >
    <option :value="null">{{ placeholder || $t("PhiV3YearPicker.emptyYear") }}</option>
    <option
      v-for="year in years"
      :key="year.id"
      :value="year"
    >{{ year.name }}</option>
  </select>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from '@/modules/api/mixins/useApi';
import apiYears from '@/modules/v3/api/years';

export default {
  name: 'v3-year-picker',
  mixins: [useI18n, useApi],
  api: apiYears,

  props: {
    value: {
      type: Object,
      required: false,
    },

    autoSelect: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    preserve: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedYear: null,
      years: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.selectedYear = newVal;
      },
    },
  },

  async mounted() {
    await this.fetchYears();

    if (this.preserve && this.selectSavedYear()) {
      return;
    }

    this.autoSelect && this.selectCurrentYear();
  },

  methods: {
    selectYear(year) {
      this.selectedYear = year;
      localStorage.setItem(
        'v3.year.picker.value',
        JSON.stringify(this.selectedYear)
      );
      this.$emit('input', this.selectedYear);
    },

    async fetchYears() {
      try {
        this.years = await this.$api.getYears();
      } catch (err) {
        // zzzzzzz
      }
    },

    selectSavedYear() {
      let storedYear = localStorage.getItem('v3.year.picker.value');
      if (storedYear) {
        try {
          this.selectYear(JSON.parse(storedYear));
          return true;
        } catch (err) {
          return false;
        }
      }
      return false;
    },

    selectCurrentYear() {
      if (!this.years || !this.years.length) {
        return;
      }

      for (let i in this.years) {
        if (this.years[i].current == '1' || this.years[i].current == true) {
          this.selectYear(this.years[i]);
          return;
        }
      }

      // Si no hay ningun año marcado como "current", seleccionar el ultimo
      this.selectYear(this.years[this.years.length - 1]);
    },
  },

  i18n: {
    es: {
      'PhiV3YearPicker.emptyYear': 'Selecciona un año',
    },

    en: {
      'PhiV3YearPicker.emptyYear': 'Pick a year',
    },
  },
};
</script>